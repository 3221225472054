import React, { useState } from "react";
import styles from "./style.module.css";
import {
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { message } from "antd/es";
import {
  createSubscriptionData,
  fetchSubscriptionData,
} from "../../../../Redux/Subscription/subscriptionReducer";
const initialState = {
  planName: "",
  timePeriod: "",
  price: "",
  subscriptionProductId: "",
  userType: "USER",
};
const CreateSubscription = ({ setCreate }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialState);
  const handleMonthChange = (e) => {
    // setForm({ ...form, planName: e.target.value });
    // Update timePeriod based on selected month option
    if (e.target.value === "OneMonth") {
      setForm({ ...form, planName: e.target.value, timePeriod: 1 }); // set timePeriod to 1 for OneMonth
    } else if (e.target.value === "ThreeMonths") {
      setForm({ ...form, planName: e.target.value, timePeriod: 3 });
    } else if (e.target.value === "SixMonths") {
      setForm({ ...form, planName: e.target.value, timePeriod: 6 });
    } else {
      setForm({ ...form, planName: e.target.value, timePeriod: 12 }); // clear timePeriod for other options
    }
  };

  const createPlan = () => {
    if (!form.planName) {
      message.error("Plan Name is Required");
    } else {
      if (!form.timePeriod) {
        message.error("Time Period is Required");
      } else {
        if (!form.price) {
          message.error("Price is Required");
        } else {
          dispatch(createSubscriptionData(form))
            .then((res) => {
              dispatch(fetchSubscriptionData("", "USER")).then(() => {
                setCreate(false);
                message.success("Plan Created Successfully");
              });
            })
            .catch((err) => {
              message.error("Something went Wrong");
            });
        }
      }
    }
  };
  return (
    <div className={styles.MainContainer}>
      <div className={styles.container1}>
        <p class="font-bold text-lg md:text-base">
          <a
            onClick={() => setCreate(false)}
            class="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Plan
          </a>{" "}
          <a class="mx-2">&gt; </a>
          <a>Create Plan</a>
        </p>

        <div className="create-header">
          <h1>Create New Plan</h1>
          <div className={styles.btn}>
            <Button
              colorScheme="black"
              variant="outline"
              onClick={() => setCreate(false)}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color="#fff"
              variant="solid"
              onClick={createPlan}
            >
              Save
            </Button>
          </div>
        </div>

        <div className={styles.container}>
          <p>Plan Name</p>
          <Select
            placeholder="Choose Plan Name"
            onChange={handleMonthChange}
            // onChange={(e) => setForm({ ...form, planName: e.target.value })}
            value={form.planName}
          >
            <option value="OneMonth">1 Month</option>
            <option value="ThreeMonths">3 Months</option>
            <option value="SixMonths">6 Months</option>
            <option value="TwelvMonths">12 Months</option>
          </Select>
          <p>Subscription Product Id</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Subscription Product Id"
              type="text"
              onChange={(e) =>
                setForm({ ...form, subscriptionProductId: e.target.value })
              }
              value={form.subscriptionProductId}
            />
            <InputRightAddon children="ID" className={styles.sideItem} />
          </InputGroup>
          <p>Price</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Enter Price"
              type="Number"
              onChange={(e) => setForm({ ...form, price: e.target.value })}
              value={form.price}
            />
            <InputRightAddon className={styles.sideItem} children="Є" />
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscription;
