import React, { forwardRef, useEffect, useState } from "react";
import styles from "./style.module.css";
import { Button, Input, CloseButton, Stack } from "@chakra-ui/react";
// import { createCurrentVoucherData, fetchCurrentVoucherData } from '../../../Redux/Voucher/CurrentVoucherReducer'
import { useDispatch } from "react-redux";
import { message, Select } from "antd";
import { userRequest } from "../../../requestMethod";
import {
  createNotificationData,
  fetchNotificationData,
} from "../../../Redux/PushNotification/NotificationReducer";
import pushIcon from "../../../assets/icons/pushIcon.svg";
import ReactDatePicker from "react-datepicker";
import { adjustedDate } from "../../../utils/common";
import SelectCities from "../../../Components/SelectCities";

const CreateNotification = ({ setCreate, type, page }) => {
  const initialState = {
    name: "Admin",
    title: "",
    body: "",
    userType: "PARTNER",
    status: "Upcoming",
    partnerId: "",
    cities: [],
    releaseDate: "",
    releaseTime: "",
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(initialState);
  const [partners, setPartners] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  console.log("form", form);
  const currentDate = new Date().toISOString().split("T")[0];
  console.log("partners", partners);
  const onSearch = (value) => {
    setSearch(value);
  };
  const onChange = (value) => {
    setForm({ ...form, partnerId: value });
  };
  useEffect(() => {
    userRequest
      .get(
        `/admin/partner/getAllPartners?page=1&limit=100&search=${search}&status=Active`
      )
      .then((res) => {
        // Filter out duplicates based on partnerId
        const uniquePartners = res.data.data.filter(
          (partner, index, self) =>
            index ===
            self.findIndex((p) => p.partnerId?._id === partner.partnerId?._id)
        );
        setPartners(uniquePartners);
      })
      .catch((err) => {
        console.error("Error fetching partners:", err);
      });
  }, [search]);
  const addData = () => {
    if (!form.title) {
      message.error("Enter title");
    } else if (!form.partnerId) {
      message.error("Enter partnerId");
    } else if (!form.body) {
      message.error("Body cann't be empty");
    } else if (!form.userType) {
      message.error("Please Select Type");
    } else if (!form.cities) {
      message.error("Select cities");
    } else if (!form.releaseDate) {
      message.error("Select releaseDate");
    } else if (!form.releaseTime) {
      message.error("Select releaseTime");
    } else {
      dispatch(createNotificationData(form))
        .then((data) => {
          console.log("Success:", data); // Log success data
          dispatch(fetchNotificationData(type, "", page));
          setCreate(false);
          message.success("Notification created successfully");
        })
        .catch((error) => {
          console.log("Error:", error); // Log error message
          message.error(`Error creating notification: ${error}`);
        });
    }
  };

  const addCity = () => {
    if (form.city && !form.cities.includes(form.city)) {
      setForm((prevForm) => ({
        ...prevForm,
        cities: [...prevForm.cities, prevForm.city],
        city: "",
      }));
    }
  };
  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 0);
    return date;
  };

  const minDate = getMinDate();
  const removeCity = (removedCity) => {
    setForm({
      ...form,
      cities: form.cities.filter((city) => city !== removedCity),
    });
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={value}
      onClick={onClick}
      placeholder="DD-MM-YYYY"
      style={{ cursor: "pointer" }}
      ref={ref}
    />
  ));
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const originDate = adjustedDate(date);
    setForm({ ...form, releaseDate: originDate.toISOString().split("T")[0] });
  };
  const matchingPartner = partners.find(
    (el) => el.partnerId._id === form.partnerId
  );

  return (
    <div className={styles.MainContainer}>
      <div className={styles.container1}>
        <p class={styles.breadcrumb}>
          <button
            onClick={() => setCreate(false)}
            class="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Notifications
          </button>{" "}
          <p class="mx-2">&gt; </p>
          <p>Create New Notification</p>
        </p>
        <div className={styles.header}>
          <h1>Create New Notification</h1>
          <div className={styles.btn}>
            <Button
              colorScheme="black"
              variant="outline"
              w="127px"
              onClick={() => setCreate(false)}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color="#fff"
              variant="solid"
              w="127px"
              onClick={addData}
            >
              Save
            </Button>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.resetPassword}>
            <p>Title*</p>
            <Input
              placeholder="Title (max. 50 characters)"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
            <p>Body*</p>
            <Input
              placeholder="Add Body (max. 150 characters)"
              value={form.body}
              onChange={(e) => setForm({ ...form, body: e.target.value })}
            />
            {/* <div> */}
            <p>Select Partner name</p>
            <Select
              className={"serachSelect"}
              showSearch
              placeholder="Choose Partner"
              optionFilterProp="label"
              onSearch={onSearch}
              onChange={onChange}
              options={partners?.map((el) => ({
                value: el.partnerId?._id,
                label: el?.name,
              }))}
            />
            {/* </div> */}
            {/* <Select
              className={styles.selectPartner}
              border="none"
              outline="none"
              h={8}
              focusBorderColor="transparent"
              onChange={(e) => {
                setReceiver(e.target.value);
              }}
              value={form.partnerId}
              onChange={(e) => setForm({ ...form, partnerId: e.target.value })}
              placeholder="Choose Partner"
            >
              {partners.map((el) => {
                return (
                  <option key={el.partnerId._id} value={el?.partnerId?._id}>
                    {el?.partnerId?.fullName + el?.partnerId?.lastName}
                  </option>
                );
              })}
              <option value="USER">User</option>

            </Select> */}
            <div className={styles.cityMain}>
              <p>Only Received by users in the following city:</p>
              {/* <div className={styles.citydiv}>
                <div className={styles.scrollstack}>
                  <Stack direction="row" spacing={2} align="center">
                    {form.cities.map((city) => (
                      <div key={city} className={styles.selectedCity}>
                        <p>{city}</p>
                        <CloseButton onClick={() => removeCity(city)} />
                      </div>
                    ))}
                  </Stack>
                </div>

                <input
                  placeholder="City"
                  type="text"
                  value={form.city}
                  onChange={(e) => setForm({ ...form, city: e.target.value })}
                  onKeyDown={(e) => e.key === "Enter" && addCity()}
                  onBlur={addCity}
                />
              </div> */}
              <SelectCities form={form} setForm={setForm} />
            </div>

            <div className={styles.dateDiv}>
              <p>Release on:</p>
              <ReactDatePicker
                wrapperClassName={styles.datepicker}
                className={styles.date_picker}
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomInput />}
                minDate={minDate}
                dateFormat="yyyy-MM-dd"
                popperModifiers={[
                  {
                    name: "arrow",
                    options: {
                      padding: ({ popper, reference, placement }) => ({
                        right: Math.min(popper.width, reference.width) - 24,
                      }),
                    },
                  },
                ]}
              />
              <Input
                type="time"
                onChange={(e) =>
                  setForm({ ...form, releaseTime: e.target.value })
                }
              />
            </div>
          </div>
          <div className={styles.container2}>
            <div className={styles.preview}>
              <p>Preview</p>
              <div className={styles.previewInner}>
                <span className={styles.spanIcon}>
                  <img src={pushIcon} alt="" />
                </span>
                <div>
                  <h2>{form.title}</h2>
                  <p>{form.body}</p>
                  {matchingPartner && (
                    <p
                      key={matchingPartner.partnerId._id}
                      value={matchingPartner.partnerId._id}
                    >
                      {matchingPartner.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
